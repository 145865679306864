import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from '../components/Login/Login'
import Admin from '../components/Admin/Admin'
import Home from '../components/Admin/Home';
import Collaborateurs from '../components/Admin/Collaborateurs';
import Projets from '../components/Admin/Projets';
import Facture from '../components/Admin/Facture';
import Calendrier from '../components/Admin/Calendrier';
import AdminRoutes from './AdminRoutes';
import EmployeesRoutes from './EmployeesRoutes';
import PasswordReset from '../components/Login/PasswordReset';
import Profil from '../components/Profil';


function Pages() {
  return (
    <Router basename='/app'>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/passwordReset/:id/:token' element={<PasswordReset />} />
        <Route element={<AdminRoutes />}>
          <Route path='admin' element={<Admin />}>
            <Route index element={<Home />} />
            <Route path='collaborateurs' element={<Collaborateurs />} />
            <Route path='projets' element={<Projets />} />
            <Route path='facture/:id' element={<Facture />} />
            <Route path='calendrier' element={<Calendrier />} />
          </Route>
        </Route>
        <Route element={<EmployeesRoutes />}>
          <Route path='employee' element={<Calendrier />} />
          <Route path='profil/:id' element={<Profil />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Pages;
