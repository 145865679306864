import { Alert, Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import DescriptionIcon from '@mui/icons-material/Description';
import { useEffect } from 'react';
import {
  datagridFr,
  datagridFrPagination,
} from '../../constants/datagridFrench';


function Home() {
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

    const months = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];

  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [projectsList, setProjectList] = useState([]);
  useEffect(() => {
    fetch('/app/projets')
      .then((res) => res.json())
      .then((data) => {
        const result = data;
        const reducer = (accumulator, curr) => accumulator + curr;
        for (let i = 0; i < result.length; i++) {
          const sum = JSON.parse(result[i].jour_tra).reduce(reducer);
          result[i].jour_tra = sum;
        }
        setProjectList(result);
      })
      .catch((err) => console.log(err));
  }, []);

  const columns = [
    { field: 'projectName', headerName: 'Projet', width: 200 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'tjm', headerName: 'TJM (€)', width: 120 },
    { field: 'fname', headerName: 'Collab concerné', width: 230 },
    { field: 'jour_tra', headerName: 'Jours travaillés', width: 180 },
    {
      field: 'facture',
      headerName: 'Facture',
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <NavLink to={'/admin/facture/' + params.row.id}>
              <DescriptionIcon
                sx={{
                  color: '#9292AE',
                }}
              />
            </NavLink>
          </>
        );
      },
    },
  ];

  const [dateValid, setDateValid] = useState(null);
  const [date, setDate] = useState(null);

  const validateDate = () => {
    fetch('/app/date', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dateVal: dateValid,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          let msg = '';
          if (date.getMonth() === 7 || date.getMonth() === 3) {
            msg = data.success + "d'" + months[date.getMonth()];
          } else {
            msg = data.success + 'de ' + months[date.getMonth()];
          }
          setSuccessMsg(msg);
          setState({ ...state, open: true });
          setDateValid(null);
          setDate(null);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      dateValid != null &&
      dateValid.match(/^[2][0][0-9]{2}[\-]([0][0-9]|[1][0-2])$/)
    ) {
      validateDate();
    } else {
      setSuccessMsg('');
      setErrorMsg("La date n'est pas valide");
      setState({ ...state, open: true });
    }
  };

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    if (date.getMonth() < 9) {
      setDateValid(year + '-0' + (date.getMonth() + 1));
    } else {
      setDateValid(year + '-' + (date.getMonth() + 1));
    }
    setDate(date);
  };

  return (
    <div className='collabs'>
      <h1>Bienvenue</h1>
      <div className='home__container'>
        <div className='input_container'>
          <div className='input'>
            <p>Valider la date</p>
            <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
              <DatePicker
                views={['month', 'year']}
                value={date}
                dateformat='yyyy/MM'
                minDate='2018'
                maxDate='2100'
                onChange={(newValue) => {
                  handleDateChange(newValue);
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <Button
          onClick={handleSubmit}
          variant='contained'
          size='large'
          sx={{
            backgroundColor: '#DBA09E',
            fontFamily: 'Poppins, sans-serif !important',

            '&:hover': {
              backgroundColor: '#EA4C3C',
            },
          }}
        >
          Valider
        </Button>
      </div>
      {errorMsg && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClose}
            severity='error'
            sx={{
              width: '100%',
              color: '#EA4C3C',
              fontFamily: 'Poppins, sans-serif !important',
            }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      )}
      {successMsg && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={horizontal + vertical}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            sx={{
              width: '100%',
              fontFamily: 'Poppins, sans-serif !important',
            }}
          >
            {successMsg}
          </Alert>
        </Snackbar>
      )}

      <div style={{ width: 'auto', height: '90vh' }}>
        <DataGrid
          disableSelectionOnClick
          rows={projectsList}
          columns={columns}
          pageSize={50}
          sx={{
            fontFamily: 'Poppins !important',
          }}
          localeText={datagridFr}
          componentsProps={datagridFrPagination}
        />
      </div>
    </div>
  );
}

export default Home;
