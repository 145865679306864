import { Alert, Box, Button, DialogTitle, Paper, Snackbar } from '@mui/material';
import { useContext, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Context from '../../context';

function AddEmployee({ setOpen }) {
  const { setEmployeesList } = useContext(Context);

  const [errorMsg, setErrorMsg] = useState('');

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };


  const [showPwd, setShowPwd] = useState(false);
  const handleClickShowPassword = () => {
    setShowPwd(!showPwd);
  };

  const [firstName, setFirstname] = useState('');
  const [lastName, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAdresse] = useState('');

  const addCollab = () => {
    fetch('/app/employees', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        phone,
        address,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.err) throw new Error(data.err);
        fetch('/app/employees')
          .then((res) => res.json())
          .then((data) => {
            setEmployeesList(data);
            setOpen(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        setErrorMsg(err.message);
        setState({ ...state, open: true });
      });
  };

  return (
    <div className='collabs'>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity='error'
          sx={{
            width: '100%',
            color: '#EA4C3C',
            fontFamily: 'Poppins, sans-serif !important',
          }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <DialogTitle sx={{textAlign: 'center'}}>
        <h1>Nouveau collaborateur</h1>
      </DialogTitle>
      <div className='collabs__container'>
        <Paper
          elevation={1}
          sx={{
            padding: '3rem',
          }}
        >
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='prenom'>Prénom*</label>
              <input
                id='prenom'
                type='text'
                value={firstName}
                onChange={(event) => {
                  setFirstname(event.target.value);
                }}
              />
            </div>
            <div className='input'>
              <label htmlFor='nom'>Nom*</label>
              <input
                id='nom'
                type='text'
                value={lastName}
                onChange={(event) => {
                  setLastname(event.target.value);
                }}
              />
            </div>
          </div>
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='email'>Email*</label>
              <input
                id='email'
                type='email'
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            <div className='input'>
              <label htmlFor='mdp'>Mot de Passe*</label>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  position: 'relative',
                  width: '100%',
                }}
                className='show-pwd'
              >
                <input
                  id='mdp'
                  type={showPwd ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  placeholder='*******'
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: '50%',
                    transform: 'translate(0,50%)',
                  }}
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPwd ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Box>
            </div>
          </div>
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='tel'>Téléphone</label>
              <input
                id='tel'
                type='text'
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </div>
            <div className='input'>
              <label htmlFor='ad'>Adresse</label>
              <input
                id='ad'
                type='text'
                value={address}
                onChange={(event) => {
                  setAdresse(event.target.value);
                }}
              />
            </div>
          </div>
          <Button
            onClick={() => {
              addCollab();
            }}
            variant='contained'
            sx={{
              backgroundColor: '#DBA09E',
              fontFamily: 'Poppins, sans-serif !important',

              '&:hover': {
                backgroundColor: '#EA4C3C',
              },
            }}
          >
            Ajouter
          </Button>
        </Paper>
      </div>
    </div>
  );
}

export default AddEmployee;
