import { styled } from '@mui/material/styles';
import { Alert, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const StyledButton = styled(Button)({
  marginTop: '.6rem',
  boxShadow: 'none',
  backgroundColor: '#DBA09E',
  textTransform: 'none',
  fontFamily: 'Poppins !important',
  fontWeight: 'bold',

  '&:hover': {
    backgroundColor: '#EA4C3C',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#EA4C3C',
    borderColor: '#DBA09E',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});

function PasswordReset() {
  const { id, token } = useParams();
  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState('');
  const [errorUI, setErrorUI] = useState('');

  const [pwd, setPwd] = useState('');
  const [confPwd, setConfPwd] = useState('');

  useEffect(() => {
    fetch('/app/forgotPwd/checkLink', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        token,
      }),
    })
      .then((res) => {
        if (res.status === 500) throw new Error('Erreur interne du serveur');
        return res.json();
      })
      .then((data) => {
        if (data.err) return setErrorUI(data.err);
        setErrorUI('');
      })
      .catch((err) => setErrorUI(err.message));
  }, [id,token]);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/app/forgotPwd/reset', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          token,
          pwd,
          confPwd,
        }),
      }).then((res) => {
        if (res.status === 500) throw new Error('Erreur interne du serveur');
        return res.json();
      });
      if (response.err) return setErrorMsg(response.err);
      navigate('/');

    } catch (err) {
      setErrorMsg(err);
    }
  };

  return (
    <main>
      {errorUI ? (
        <h1>{errorUI}</h1>
      ) : (
        <form className='forgot'>
          {errorMsg ? (
            <Alert
              severity='error'
              className='alert'
              sx={{
                color: '#EA4C3C',
                fontFamily: 'Poppins, sans-serif',
                borderWidth: '2px',
              }}
            >
              {errorMsg}
            </Alert>
          ) : (
            <h3>Réinitialisez votre mot de passe</h3>
          )}
          <div className='ctn'>
            <div className='input' style={{ marginBottom: '1rem' }}>
              <label>Nouveau mot de passe</label>
              <input
                type='password'
                className='forgot_input'
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              />
            </div>
            <div className='input'>
              <label>Confirmation</label>
              <input
                type='password'
                className='forgot_input'
                value={confPwd}
                onChange={(e) => setConfPwd(e.target.value)}
              />
            </div>
            {pwd !== confPwd && (
              <small style={{ color: '#EA4C3C' }}>
                Les mots de passe ne correspondent pas
              </small>
            )}
          </div>

          <StyledButton size='large' variant='contained' onClick={handleClick}>
            Réinitialiser
          </StyledButton>
        </form>
      )}
    </main>
  );
}

export default PasswordReset;
