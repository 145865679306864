import { Navigate, Outlet } from 'react-router-dom';

function AdminRoutes() {
  const userCookie = localStorage.getItem('userInfo');
  
  return userCookie ? (
    JSON.parse(userCookie).isAdmin === 'admin' ? (
      <Outlet />
    ) : (
      <Navigate to='/employee' />
    )
  ) : (
    <Navigate to='/' />
  );
}

export default AdminRoutes