import { Button, DialogTitle, Paper } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import Context from '../../context';

function EditProject({ id, setOpenPopUp }) {
  const { setProjectList } = useContext(Context);

  const [pstatus, setStatus] = useState('');
  const [ptjm, setTjm] = useState('');
  const [pname, setName] = useState('');
  const [invoiceTo, setInvoiceTo] = useState('');
  const [invoiceDestination, setInvoiceDestination] = useState('');

  useEffect(() => {
    fetch(`/app/projets/${id}`)
      .then((res) => res.json())
      .then((response) => {
        setName(response[0].projectName);
        setStatus(response[0].status);
        setTjm(response[0].tjm);
        setInvoiceTo(response[0].invoiceTo);
        setInvoiceDestination(response[0].invoiceDestination);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const updateProject = (id) => {
    fetch(`/app/projets/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        projectName: pname,
        tjm: ptjm,
        status: pstatus,
        workId: id,
        invoiceTo: invoiceTo,
        invoiceDestination: invoiceDestination,
      }),
    })
      .then(() => {
        fetch('/app/projets')
          .then((res) => res.json())
          .then((data) => {
            setProjectList(data);
            setOpenPopUp(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='collabs'>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <h1>Editer le projet</h1>
      </DialogTitle>
      <div className='collabs__container'>
        <Paper
          elevation={1}
          sx={{
            padding: '3rem',
          }}
        >
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='prenom'>Projet</label>
              <input
                id='prenom'
                type='text'
                value={pname}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </div>
            <div className='input'>
              <label htmlFor='nom'>TJM (€)</label>
              <input
                id='nom'
                type='text'
                value={ptjm}
                onChange={(event) => {
                  setTjm(event.target.value);
                }}
              />
            </div>
          </div>
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='email'>Facture adressée à</label>
              <input
                id='email'
                type='email'
                value={invoiceTo}
                onChange={(event) => {
                  setInvoiceTo(event.target.value);
                }}
              />
            </div>
            <div className='input'>
              <label htmlFor='mdp'>Facture expédiée à</label>
              <input
                id='email'
                type='email'
                value={invoiceDestination}
                onChange={(event) => {
                  setInvoiceDestination(event.target.value);
                }}
              />
            </div>
          </div>
          <Button
            onClick={() => {
              updateProject(id);
            }}
            variant='contained'
            sx={{
              backgroundColor: '#DBA09E',
              boxShadow: 'none',
              fontFamily: 'Poppins, sans-serif !important',

              '&:hover': {
                backgroundColor: '#EA4C3C',
              },
            }}
          >
            Mettre à jour
          </Button>
          <Button
            onClick={() => {
              setOpenPopUp(false);
            }}
            variant='outlined'
            sx={{
              marginLeft: '.5rem',
              borderColor: '#393A62',
              color: '#393A62',
              fontFamily: 'Poppins, sans-serif !important',

              '&:hover': {
                backgroundColor: '#393A62',
                color: 'white',
                border: 'none',
              },
            }}
          >
            Annuler
          </Button>
        </Paper>
      </div>
    </div>
  );
}

export default EditProject;
