import './Login.css';
import logo from './logo.png';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const StyledButton = styled(Button)({
  marginTop: '.6rem',
  boxShadow: 'none',
  backgroundColor: '#DBA09E',
  textTransform: 'none',
  fontFamily: 'Poppins !important',
  fontWeight: 'bold',

  '&:hover': {
    backgroundColor: '#EA4C3C',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#EA4C3C',
    borderColor: '#DBA09E',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});

const CssTextField = styled(TextField)({
  marginBlock: '.6rem',
  '& label.Mui-focused': {
    color: '#747399',
    fontWeight: 'bold',
    fontFamily: 'Poppins !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#747399',
  },
  '& .MuiInput-underline:before:hover': {
    borderBottomColor: '#747399',
  },
});

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // Mot de passe oublié
  const [forgot, setForgot] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const handleForgot = (e) => {
  e.preventDefault();
  setIsLoading(true)
  fetch('/app/forgotPwd', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: forgotEmail,
    }),
  })
    .then((res) => {
      setIsLoading(false);
      if (res.status === 500) throw new Error('Erreur interne du serveur');
      return res.json();
    })
    .then((data) => {
      if (data.err) return setErrorMsg(data.err);
      setErrorMsg('');
      setSuccessMsg(data.success);
      setForgotEmail('');
      setState({ ...state, open: true });
    })
    .catch((err) => setErrorMsg(err.message));
  };

  // Login Form
  const [values, setValues] = React.useState({
    email: '',
    password: '',
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch('/app/login', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 500) throw new Error('Erreur interne du serveur');
        return res.json();
      })
      .then((data) => {
        if (data.err) return setErrorMsg(data.err);
        localStorage.setItem('userInfo', JSON.stringify(data));
        if (data.isAdmin === 'admin') navigate('/admin');
        else if (data.isAdmin === 'noadmin') navigate('/employee');
      })
      .catch((err) => setErrorMsg(err.message));
  };

  return (
    <>
      <div id='container'>
        <button className='learn-more button'>
          <a href='https://omdata.fr/'>
            <span className='circle' aria-hidden='true'>
              <HomeIcon
                fontSize='medium'
                className='icon arrow'
                sx={{
                  color: 'white',
                }}
              />
            </span>
            <span className='button-text'>Accueil</span>
          </a>
        </button>
      </div>

      <main>
        {successMsg && (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            key={horizontal + vertical}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{
                width: '100%',
                fontFamily: 'Poppins, sans-serif !important',
              }}
            >
              {successMsg}
            </Alert>
          </Snackbar>
        )}
        {!forgot ? (
          <div className='container'>
            <div className='left'>
              <img src={logo} alt='' />
            </div>
            <div className='right'>
              <form>
                {errorMsg ? (
                  <Alert
                    severity='error'
                    className='alert'
                    sx={{
                      color: '#EA4C3C',
                      fontFamily: 'Poppins, sans-serif',
                      borderWidth: '2px',
                    }}
                  >
                    {errorMsg}
                  </Alert>
                ) : (
                  <h2>Bienvenue</h2>
                )}
                <CssTextField
                  type='email'
                  id='input-with-icon-textfield'
                  label='Email'
                  onChange={handleChange('email')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  variant='standard'
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <CssTextField
                    fullWidth
                    type={values.showPassword ? 'text' : 'password'}
                    id='input-with-icon'
                    value={values.password}
                    label='Mot de Passe'
                    onChange={handleChange('password')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant='standard'
                  />

                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: 0,
                      bottom: '40%',
                      transform: 'translate(0,57%)',
                    }}
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>

                <h5
                  onClick={() => {
                    setErrorMsg('');
                    setForgot(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Mot de passe oublié?
                </h5>

                <StyledButton
                  size='large'
                  variant='contained'
                  onClick={handleSubmit}
                >
                  {isLoading ? '...' : 'Connexion'}
                </StyledButton>
              </form>
            </div>
          </div>
        ) : (
          <form className='forgot'>
            {errorMsg ? (
              <Alert
                severity='error'
                className='alert'
                sx={{
                  color: '#EA4C3C',
                  fontFamily: 'Poppins, sans-serif',
                  borderWidth: '2px',
                }}
              >
                {errorMsg}
              </Alert>
            ) : (
              <h3>Saisissez votre adresse email</h3>
            )}
            <input
              type='email'
              className='forgot_input'
              value={forgotEmail}
              placeholder='exemple@omdata.fr'
              onChange={(e) => setForgotEmail(e.target.value)}
            />
            <StyledButton
              startIcon={
                !isLoading && (
                  <EmailIcon
                    sx={{
                      color: 'white !important',
                    }}
                  />
                )
              }
              size='large'
              variant='contained'
              onClick={handleForgot}
            >
              {isLoading ? '...' : 'Soumettre'}
            </StyledButton>
            <IconButton
              className='backToLogin'
              onClick={() => {
                setErrorMsg('');
                setForgot(false);
              }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  color: '#393A62',
                  fontSize: '30px',
                }}
              />
            </IconButton>
          </form>
        )}
      </main>
    </>
  );
}

export default Login;
