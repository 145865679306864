import { useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, IconButton, Dialog } from '@mui/material';
import Swal from 'sweetalert2';
import Context from '../../context';
import AddEmployee from './AddEmployee';
import EditCollab from './EditCollab';

import {
  datagridFr,
  datagridFrPagination,
} from '../../constants/datagridFrench';

function Collaborateurs() {
  const { setEmployeesList, employeesList } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [openPopUp, setOpenPopUp] = useState(false);

  // Delete Employee
  const deleteEmployee = (id) => {
    fetch(`/app/employees/${id}`, {
      method: 'delete',
    })
      .then((res) => {
        fetch('/app/employees')
          .then((res) => res.json())
          .then((data) => {
            setEmployeesList(data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // Warning message
  function popup(id, fname, lname) {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Cette action est irréversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#747399',
      cancelButtonColor: '#DBA09E',
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Oui, supprimer ' + fname + ' ' + lname,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmployee(id);
        Swal.fire('Supprimé!', 'Le collaborateur a été supprimé.', 'success');
      }
    });
  }

  const columns = [
    { field: 'lastName', headerName: 'Nom', width: 130 },
    { field: 'firstName', headerName: 'Prénom', width: 130 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'phone', headerName: 'Téléphone', width: 150 },
    { field: 'address', headerName: 'Adresse', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <Chip
              sx={{
                backgroundColor: '#DBA09E',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                fontFamily: 'Poppins, sans-serif !important',

                '&:hover': {
                  backgroundColor: 'white',
                  color: '#DBA09E',
                  border: '1px solid #DBA09E',
                },
              }}
              label='Editer'
              variant='outlined'
              onClick={() => {
                setOpenPopUp(true);
                setId(params.row.id);
              }}
            />
            <IconButton
              onClick={() => {
                popup(params.row.id, params.row.firstName, params.row.lastName);
              }}
              disabled={params.row.IsAdmin === 'admin'}
            >
              <DeleteIcon
                fontSize='large'
                sx={{
                  color:
                    params.row.IsAdmin === 'admin' ? '#7473994d' : '#747399',
                }}
              />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div className='collabs'>
      <h1>Collaborateurs</h1>

      <div style={{ width: 'auto', height: '70vh' }}>
        <DataGrid
          disableSelectionOnClick
          rows={employeesList}
          columns={columns}
          pageSize={50}
          sx={{
            fontFamily: 'Poppins !important',
          }}
          localeText={datagridFr}
          componentsProps={datagridFrPagination}
        />
      </div>

      <Fab
        aria-label='add'
        sx={{
          backgroundColor: '#ea4c3c',
          color: 'white',
          position: 'fixed',
          right: '2vw',
          bottom: '10vh',

          '&:hover': {
            backgroundColor: '#DBA09E',
          },
        }}
        onClick={() => setOpen(true)}
        size='large'
      >
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md'>
        <AddEmployee setOpen={setOpen} />
      </Dialog>
      <Dialog
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        maxWidth='md'
      >
        <EditCollab setOpenPopUp={setOpenPopUp} id={id} />
      </Dialog>
    </div>
  );
}

export default Collaborateurs;
