import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [src, setSrc] = React.useState('');
  const navigate = useNavigate()

  const { id } = JSON.parse(localStorage.getItem('userInfo'));
  const [fullName, setFullName] = React.useState('');
  const pages = [
    { nom: 'Accueil', link: '/employee' },
    { nom: 'Profil', link: `/profil/${id}` },
    { nom: 'Déconnexion', link: '/' },
  ];

  React.useEffect(() => {
    fetch(`/app/employees/${id}`)
      .then((res) => res.json())
      .then((response) => {
        setFullName(`${response[0].firstName} ${response[0].lastName}`);
        fetch('/app/upload/' + id)
          .then((res) => res.json())
          .then((data) => setSrc(data[0].url));
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem('userInfo');
    navigate('/');
  };

  return (
    <AppBar
      position='static'
      sx={{
        backgroundColor: '#747399',
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={fullName} src={src} />
            </IconButton>
          </Box>
          <Typography
            variant='h6'
            noWrap
            component='h6'
            sx={{
              mr: 2,
              ml: 2,
              fontFamily: 'Poppins, sans-serif !important',
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {fullName}
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 'auto' }}>
            <NavLink to='/employee' className='nav-link'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: 'white',
                  fontFamily: 'Poppins, sans-serif !important',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem',
                }}
              >
                Accueil <HomeIcon />
              </Button>
            </NavLink>
            <NavLink to={'/profil/' + id} className='nav-link'>
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: 'white',
                  fontFamily: 'Poppins, sans-serif !important',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem',
                }}
              >
                Profil <Person2Icon />
              </Button>
            </NavLink>
            <Button
              onClick={handleLogOut}
              sx={{
                my: 2,
                color: 'white',
                fontFamily: 'Poppins, sans-serif !important',
                display: 'flex',
                alignItems: 'center',
                gap: '.5rem',
              }}
            >
              Déconnexion <LogoutIcon />
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none', marginLeft: 'auto' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon
                sx={{
                  fontSize: '32px',
                }}
              />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <div key={page.nom}>
                  <NavLink
                    to={page.link}
                    onClick={page.nom === 'Déconnexion' && handleLogOut}
                  >
                    <MenuItem key={page.nom} onClick={handleCloseNavMenu}>
                      <Typography textAlign='center'>{page.nom}</Typography>
                    </MenuItem>
                  </NavLink>
                </div>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
