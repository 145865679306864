import { Navigate, Outlet } from 'react-router-dom';

function EmployeesRoutes() {
  const userCookie = localStorage.getItem('userInfo');

  return userCookie ? (
    JSON.parse(userCookie).isAdmin === 'noadmin' ? (
      <Outlet />
    ) : (
      <Navigate to='/admin' />
    )
  ) : (
    <Navigate to='/' />
  );
}

export default EmployeesRoutes;
