import { useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, Dialog, IconButton } from '@mui/material';
import Swal from 'sweetalert2';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Context from '../../context';
import {
  datagridFr,
  datagridFrPagination,
} from '../../constants/datagridFrench';
import AddProjet from './AddProjet';
import EditProject from './EditProject';
import AffecterCollab from './AffecterCollab';

function Projets() {
  const { projectsList, setProjectList } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openAffect, setOpenAffect] = useState(false);
  const [id, setId] = useState('');
  const [prId, setPrId] = useState('');

  // Delete Project
  const deleteProject = (id) => {
    fetch(`/app/projets/${id}`, {
      method: 'delete',
    })
      .then(() => {
        fetch('/app/projets')
          .then((res) => res.json())
          .then((data) => {
            setProjectList(data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // Warning message
  function popup(id, pname) {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Cette action est irréversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#747399',
      cancelButtonColor: '#DBA09E',
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Oui, supprimer ' + pname,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProject(id);
        Swal.fire('Supprimé!', 'Le projet a été supprimé.', 'success');
      }
    });
  }

  const columns = [
    { field: 'projectName', headerName: 'Nom', width: 200 },
    { field: 'tjm', headerName: 'TJM (€)', width: 80 },
    { field: 'fname', headerName: 'Collab concerné', width: 230 },
    { field: 'date', headerName: 'Date', width: 100 },
    {
      field: 'invoiceTo',
      headerName: 'Facture adressée à',
      width: 200,
    },
    {
      field: 'invoiceDestination',
      headerName: 'Facture expédiée à',
      width: 200,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <Chip
              sx={{
                backgroundColor: '#DBA09E',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                marginRight: '.5rem',

                '&:hover': {
                  backgroundColor: 'white',
                  color: '#DBA09E',
                  border: '1px solid #DBA09E',
                },
              }}
              label='Editer'
              variant='outlined'
              onClick={() => {
                setOpenPopUp(true);
                setId(params.row.id);
              }}
            />
            <IconButton
              onClick={() => {
                popup(params.row.id, params.row.projectName);
              }}
            >
              <DeleteIcon
                fontSize='large'
                sx={{
                  color: '#9292AE',
                }}
              />
            </IconButton>
              <IconButton
                onClick={() => {
                  setOpenAffect(true);
                  setPrId(params.row.PrID);
                }}
              >
                <AddBoxIcon
                  className='projectListAdd'
                  fontSize='large'
                  sx={{
                    color: '#393A62',
                  }}
                />
              </IconButton>
          </div>
        );
      },
    },
  ];

  // const filtred = projectsList.reduce((accumulator, current) => {
  //   if (checkIfAlreadyExist(current)) {
  //     return accumulator;
  //   } else {
  //     return [...accumulator, current];
  //   }

  //   function checkIfAlreadyExist(currentVal) {
  //     return accumulator.some((item) => {
  //       return item.PrID === currentVal.PrID && item.fname === currentVal.fname;
  //     });
  //   }
  // }, []);

  return (
    <div className='collabs'>
      <h1>Projets</h1>

      <div style={{ width: 'auto', height: '70vh' }}>
        <DataGrid
          disableSelectionOnClick
          rows={projectsList}
          columns={columns}
          pageSize={50}
          sx={{
            fontFamily: 'Poppins !important',
          }}
          localeText={datagridFr}
          componentsProps={datagridFrPagination}
        />
      </div>

      <Fab
        aria-label='add'
        sx={{
          backgroundColor: '#ea4c3c',
          color: 'white',
          position: 'fixed',
          right: '2vw',
          bottom: '10vh',

          '&:hover': {
            backgroundColor: '#DBA09E',
          },
        }}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md'>
        <AddProjet setOpen={setOpen} />
      </Dialog>

      <Dialog
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        maxWidth='md'
      >
        <EditProject setOpenPopUp={setOpenPopUp} id={id} />
      </Dialog>
      <Dialog
        open={openAffect}
        onClose={() => setOpenAffect(false)}
        maxWidth='md'
      >
        <AffecterCollab setOpenAffect={setOpenAffect} prId={prId} />
      </Dialog>
    </div>
  );
}

export default Projets;
