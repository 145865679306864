import './Calendrier.css';
import { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import { Alert, Button, Snackbar } from '@mui/material';

export default function Calendar() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const id = userInfo.id; //get info 
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [state, setState] = useState({
    open: false,
    vertical: userInfo.isAdmin === 'noadmin' ? 'bottom' : 'top',
    horizontal: userInfo.isAdmin === 'noadmin' ? 'left' : 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  let numberDayMonth = 0;

  const listMonth = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  // get the project's to diplay them in select
  const [projectsList, setProjectsList] = useState([]);

  //catch the selected project (id)
  const [prId, setPrId] = useState('');
  const [tjm, setTjm] = useState('');
  const [prName, setPrName] = useState('');

  function updateProject(date) {
    if (date !== 'NaN-aN') {
      fetch('/app/projets/projectsName', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          date,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const workDay = JSON.parse(result[i].jour_tra);
            for (let j = 0; j < 31; j++) {
              if (workDay[j] !== 1 && workDay[j] !== 0.5) {
                workDay[j] = 0;
              }
            }
            result[i].jour_tra = workDay;
          }
          setProjectsList(result);
        })
        .catch((err) => console.log(err));
    }
  }
  //stock the project's in select
  const [selectOption, setSelectOption] = useState([]);

  useEffect(() => {
    const selOptions = [];

    const ids = projectsList.map((o) => o.projectName);
    const filtered = projectsList.filter(
      ({ projectName }, index) => !ids.includes(projectName, index + 1)
    );

    for (let i = 0; i < filtered.length; i++) {
      let obj = {};
      if (filtered.length > 0) {
        obj['id'] = filtered[i].id;
        obj['tjm'] = filtered[i].tjm;
        // obj["date"] = projectsList[i].date;
        obj['value'] = filtered[i].projectName;
        obj['label'] = filtered[i].projectName;
        obj['workDay'] = filtered[i].jour_tra;
      }
      selOptions.push(obj);
    }
    setSelectOption(selOptions);
  }, [projectsList]);

  const handle = (e) => {
    const evt = JSON.parse(e.target.value);
    setPrId(evt.id);
    setTjm(evt.tjm);
    setPrName(evt.label);
    setValueMonthDay(evt.workDay);
    const reducer = (acc, curr) => acc + curr;
    setTotal(evt.workDay.reduce(reducer));
  };
  //fetch the validated date from the admin (if he select 04/2022 we display 05/2022)
  //always we increase with 1 month
  const [dateVal, setDateVal] = useState('');

  useEffect(() => {
    let date;
    fetch('/app/date')
      .then((res) => res.json())
      .then((response) => {
        if (response.length > 0) {
          setDateVal(response[0].dateVal);
          date = response[0].dateVal;
          let year = parseInt(date.slice(0, 4)); //get the validate date
          let month = parseInt(date.slice(5, 7)); //get the validate month

          let monthTosend;
          //Increase with 1 month
          if (month === 12) {
            month = 1;
            year += 1;
          } else {
            month += 1;
          }
          let yearTosend = year;
          monthTosend = ('0' + month).slice(-2);
          const dateTosend = yearTosend + '-' + monthTosend;

          updateProject(dateTosend);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  var year = parseInt(dateVal.slice(0, 4)); //get the validate date
  var month = parseInt(dateVal.slice(5, 7)); //get the validate month

  var monthTosend;
  //Increase with 1 month
  if (month === 12) {
    month = 1;
    year += 1;
  } else {
    month += 1;
  }
  var yearTosend = year;

  monthTosend = ('0' + month).slice(-2);

  const dateTosend = yearTosend + '-' + monthTosend;

  useEffect(() => {
    updateProject(dateTosend);
  }, [prId, dateTosend]);

  //get the number of day's in the increased month
  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  //get day names in french accordind to the days in current month
  var getDaysArray = function (year, month) {
    var monthIndex = month - 1; // 0..11 instead of 1..12
    var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() === monthIndex) {
      result.push(names[date.getDay()]);
      date.setDate(date.getDate() + 1);
    }
    return result;
  };

  //return html code for the calendar
  const renderCalendar = (setValueMonthDay, valueMonthDay) => {
    const days = getDaysArray(year, month); //get the days in the month
    numberDayMonth = days.length;
    var numberStartWeek = 1; //day corresponding to a Monday of the month or the first of the month
    var tr = []; //list of calendar lines (1 line per week)
    var week = []; //last week returned
    var monthFinish = false; //all days in month have been render
    var weekNumber = 0; //number of the week in the month
    while (!monthFinish) {
      week = renderWeek(
        days[numberStartWeek - 1],
        numberStartWeek,
        numberDayMonth,
        weekNumber,
        setValueMonthDay,
        valueMonthDay
      );
      numberStartWeek += week[1]; //number of the next monday in month
      tr.push(<tr key={'week_' + weekNumber}>{week[0]}</tr>);
      monthFinish = week[2];
      weekNumber++;
    }
    return tr;
  };

  //return
  const renderWeek = (
    firstDayWeek,
    numberWeekStart,
    numberDayMonth,
    weekNumber,
    setValueMonthDay,
    valueMonthDay
  ) => {
    var td = [];
    var numberDayWeek = 0;
    const dayWeek = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];
    var dayInMonth = false;
    var monthFinish = false;
    var keyReact;
    for (let i = 0; i < 7; i++) {
      keyReact = 'dayInCalendar_' + (weekNumber * 7 + i);
      if (firstDayWeek === dayWeek[i]) {
        dayInMonth = true;
      }
      if (numberWeekStart + numberDayWeek === numberDayMonth + 1) {
        dayInMonth = false;
        monthFinish = true;
      }
      if (dayInMonth) {
        if (i === 5 || i === 6) {
          td.push(
            <DayNoWork
              date={numberWeekStart + numberDayWeek}
              key={keyReact}
              class='weekend'
            />
          );
        } else if (holydays.indexOf(numberWeekStart + numberDayWeek) !== -1) {
          td.push(
            <DayNoWork date={numberWeekStart + numberDayWeek} key={keyReact} />
          );
        } else {
          td.push(
            <Day
              date={numberWeekStart + numberDayWeek}
              key={keyReact}
              valueMonthDay={valueMonthDay}
              setValueMonthDay={setValueMonthDay}
            />
          );
        }
        numberDayWeek++;
      } else {
        td.push(<td key={keyReact}></td>);
      }
    }
    return [td, numberDayWeek, monthFinish];
  };

  const [valueMonthDay, setValueMonthDay] = useState([0]);
  const [total, setTotal] = useState(0);

  function Day(props) {
    const date = props.date - 1;
    const [valueDay, setValueDay] = useState(valueMonthDay[date]);
    const className = ['emptyDay', 'halfDay', 'wholeDay'];
    if (typeof valueDay == 'undefined') {
      setValueDay(0);
    }
    const [classDay, setClassDay] = useState('day ' + className[valueDay * 2]);
    const hanldeClick = (e) => {
      e.preventDefault();
      if (!prId) {
        setErrorMsg("Aucun projet n'a été sélectionné");
        setState({ ...state, open: true });
      } else {
        var month = props.valueMonthDay;

        if (valueDay === 0) {
          setValueDay(0.5);
          month[date] = 0.5;
        } else if (valueDay === 0.5) {
          setValueDay(1);
          month[date] = 1;
        } else {
          setValueDay(0);
          month[date] = 0;
        }
        const reducer = (acc, curr) => acc + curr;
        setTotal(valueMonthDay.reduce(reducer));
        props.setValueMonthDay(month);
      }
    };

    function classChange() {
      if (valueDay === 0.5) {
        setClassDay('day halfDay');
      } else if (valueDay === 1) {
        setClassDay('day wholeDay');
      } else {
        setClassDay('day emptyDay');
      }
    }

    useEffect(() => {
      classChange();
    }, [valueDay]);

    return (
      <>
        <td onClick={hanldeClick} className='calendarDay'>
          <div className='calendarElement'>
            <div className={classDay}>
              <span>{props.date}</span>
            </div>
            <span className='dayValue'>{valueDay}</span>
          </div>
        </td>
      </>
    );
  }

  function DayNoWork(props) {
    return (
      <>
        <td className={props.class}>
          <div className='calendarElementNoWorkDay'>
            <div className='day noWorkDay'>
              <span>{props.date}</span>
            </div>
            <span className='dayValue'></span>
          </div>
        </td>
      </>
    );
  }

  //get the number of collumns (inputs) according to day numbers & calc the SUM
  var arr = [];
  for (let i = 1; i <= daysInMonth(month, year); i++) {
    arr[i] = [i];
  }
  //get day numbers of sat & sun in the current month
  let days = []; //stock all indexes
  var date = new Date();

  for (let i = 1; i <= daysInMonth(month, year); i++) {
    days[i] = new Date(date.getFullYear(), date.getMonth(), i);
  }

  var holydays = []; //to stock the index's of saturday's and sunday's
  var size = 0;
  // holidays;
  if (year) {
    var xhReq = new XMLHttpRequest();
    xhReq.open(
      'GET',
      'https://calendrier.api.gouv.fr/jours-feries/metropole/' + year + '.json',
      false
    );
    xhReq.send(null);
    var jsonObject = JSON.parse(xhReq.responseText);
    //size number of hoiday's days,how much of hollidays in this year
    size = Object.keys(jsonObject).length;
  }
  holidayDays();

  function holidayDays() {
    for (let i = 0; i < size; i++) {
      if (parseInt(Object.keys(jsonObject)[i].slice(5, 7)) === month) {
        holydays.push(parseInt(Object.keys(jsonObject)[i].slice(8, 10)));
      }
    }
  }

  const addwork = () => {
    if (!prId) {
      setSuccessMsg('');
      setErrorMsg("Aucun projet n'a été sélectionné");
      setState({ ...state, open: true });
    } else if (dateVal === '') {
      setSuccessMsg('');
      setErrorMsg(
        "Contactez un admin pour qu'il sélectionne le mois à valider"
      );
      setState({ ...state, open: true });
    } else {
      const reducer = (acc, curr) => acc + curr;
      const total = valueMonthDay.reduce(reducer);
      fetch('/app/work/addjours', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          EmpID: id,
          PrID: prId,
          tjm: tjm,
          jour_tra: JSON.stringify(valueMonthDay),
          date: dateTosend,
        }),
      })
        .then(() => {
          setErrorMsg('');
          setSuccessMsg(
            'Votre temps de travail (' +
              total +
              ' jours) pour le projet ' +
              prName +
              ' au mois de ' +
              listMonth[month - 1] +
              ' ' +
              year +
              ' a été enregistré'
          );
          setState({ ...state, open: true });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div
        className={
          userInfo.IsAdmin === 'admin' ? 'containerAdmin' : 'containerNoadmin'
        }
      >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClose}
            severity='error'
            sx={{
              width: '100%',
              color: '#EA4C3C',
              fontFamily: 'Poppins, sans-serif !important',
            }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
        {successMsg && (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            key={horizontal + vertical}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{
                width: '100%',
                fontFamily: 'Poppins, sans-serif !important',
              }}
            >
              {successMsg}
            </Alert>
          </Snackbar>
        )}
        <div className='flexContainer'>
          {userInfo.isAdmin === 'noadmin' && <Navbar />}
          <div className='card'>
            <div className='containerCalendar'>
              <div className='calendar'>
                <div className='calendarHeader'>
                  <div className='monthCalendar'>
                    <span className='date'>
                      {month && year
                        ? listMonth[month - 1] + ' ' + year
                        : 'Pas de date'}
                    </span>
                  </div>
                  <select
                    style={{ marginBottom: '1rem' }}
                    // value={selectOption[0]}
                    onChange={handle}
                  >
                    <option selected disabled>
                      Sélectionnez un projet
                    </option>
                    {selectOption.map((option) => (
                      <option key={option.id} value={JSON.stringify(option)}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Lun</th>
                      <th>Mar</th>
                      <th>Mer</th>
                      <th>Jeu</th>
                      <th>Ven</th>
                      <th className='weekend'>Sam</th>
                      <th className='weekend'>Dim</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCalendar(setValueMonthDay, valueMonthDay)}
                  </tbody>
                </table>
                <div className='calendarFooter'>
                  <span className='total'>Temps total: {total} jours</span>
                </div>
                <div className='calendarButton'>
                  <Button
                    variant='contained'
                    className='save'
                    onClick={() => addwork()}
                    sx={{
                      fontFamily: 'Poppins, sans-serif !important',
                      backgroundColor: '#EA4C3C',
                      '&:hover': {
                        backgroundColor: '#DBA09E',
                      },
                    }}
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
