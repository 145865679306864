import Pages from './Pages';
import Context from './context';
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
});

function App() {
  // Liste des employés
  const [employeesList, setEmployeesList] = useState([]);
  useEffect(() => {
    fetch('/app/employees')
      .then((res) => res.json())
      .then((data) => {
        setEmployeesList(data);
      })
      .catch((err) => console.log(err));
  }, []);

  // Liste des projets
  const [projectsList, setProjectList] = useState([]);
  useEffect(() => {
    fetch('/app/projets')
      .then((res) => res.json())
      .then((data) => {
        setProjectList(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Context.Provider
      value={{
        setEmployeesList,
        employeesList,
        projectsList,
        setProjectList,
      }}
    >
      <ThemeProvider theme={theme}>
        <Pages />
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;
