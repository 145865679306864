import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import { Button, Paper } from '@mui/material';
import './Facture.css';


function Facture() {
  const { id } = useParams();
  const [numberInvoice, setNumberInvoice] = useState(id);
  const [tjm, setTjm] = useState(0);
  const [workDay, setWorkDay] = useState(0);
  const [invoiceTo, setInvoiceTo] = useState(0);
  const [invoiceDestination, setInvoiceDestination] = useState(0);
  const [date, setDate] = useState('.. / .. / ..');
  const [dueDate, setDueDate] = useState('.. / .. / ..');
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    fetch(`/app/work/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setTjm(data[0].tjm);
        setInvoiceTo(data[0].invoiceTo);
        setInvoiceDestination(data[0].invoiceDestination);

        //sum of work day in the month
        const reducer = (accumulator, curr) => accumulator + curr;
        const sum = JSON.parse(data[0].jour_tra).reduce(reducer);
        setWorkDay(sum);

        //get the date of the invoice
        setProjectName(data[0].projectName);
        const dateInvoice = data[0].date;
        const year = dateInvoice.slice(0, 4);
        const month = dateInvoice.slice(5);
        setDate('01/' + month + '/' + year);

        //get the due date of the invoice (2 month after the date of the invoice)
        const dateFutur = new Date(year + '-' + month + '-01'); //YYYY-MM-DD format
        dateFutur.setMonth(dateFutur.getMonth() + 2);
        const yearFutur = dateFutur.getFullYear();
        const monthFutur = (dateFutur.getMonth() + 1)
          .toString()
          .padStart(2, '0');
        setDueDate('01/' + monthFutur + '/' + yearFutur);
      })
      .catch((err) => console.log(err));
  }, [id]);

  //transform html to PDF
  const toPDF = () => {
    const html = document.getElementById('containerPdf');
    const listMonth = [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ];
    const monthWork = listMonth[parseInt(date.slice(3, 5), 10) - 1];
    const yearWork = date.slice(6);
    const nameFile =
      'facture ' +
      numberInvoice +
      ' - ' +
      projectName +
      ' - ' +
      monthWork +
      ' ' +
      yearWork +
      '.pdf';
    var opt = {
      filename: nameFile,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
    };
    html2pdf().set(opt).from(html).save();
  };


  return (
    <div className='collabs'>
      <div className="box">
          <h1>Facture n°{id}</h1>
          <Button
            onClick={toPDF}
            variant='contained'
            size='large'
            sx={{
              backgroundColor: '#DBA09E',
              fontFamily: 'Poppins, sans-serif !important',
              '&:hover': {
                backgroundColor: '#EA4C3C',
              },
            }}
          >
            Télécharger
          </Button>
      </div>
      <Paper
        elevation={3}
        sx={{
          padding: '1rem',
        }}
      >
        <div id='containerPdf'>
          <div className='logoInvoice'>
            <img src={require('./logo.png')} className='imgLogo' alt='' />
          </div>
          <div className='containerInfo'>
            <div className='omdata'>
              <h2>OMDATA Consulting</h2>
              <div className='text'>
                1 Avenue Josephine Pencalet
                <br />
                44300 Nantes
                <br />
                <a href='mailto:oussama.mamoun@omdata.fr' className='mailLink'>
                  oussama.mamoun@omdata.fr
                </a>
                <br />
                SIREN : 844 035 287
                <br />
                SIRET : 84403528700011
                <br />
                TVA intracommunautaire : FR25844035287
                <br />
                Tél. : 06 11 26 84 62
              </div>
            </div>
            <div className='invoice'>
              <h2>FACTURE</h2>
              <div className='text'>
                <span>N° de facture: </span>
                <span>{numberInvoice.toString().padStart(4, '0')}</span>
                <br />
                <span>Date de facturation: </span>
                <span>{date}</span>
                <br />
                <span>Échéance: </span>
                <span>{dueDate}</span>
              </div>
            </div>
          </div>
          <div className='invoiceInfo'>
            <div className='invoiceTo'>
              <span className='bold'>FACTURER À : </span>
              <br />
              <span>{invoiceTo}</span>
            </div>
            <div className='invoiceDestination'>
              <span className='bold'>EXPÉDIER À : </span>
              <br />
              <span>{invoiceDestination}</span>
            </div>
          </div>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th className='description'>DESCRIPTION</th>
                  <th className='timeDay'>Nombre de jours</th>
                  <th className='tjm'>Tjm HT</th>
                  <th className='price'>MONTANT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Prestation de service informatique</td>
                  <td>{workDay.toString().replace('.', ',')}</td>
                  <td>{(tjm.toFixed(2) + ' €').replace('.', ',')}</td>
                  <td>
                    {((workDay * tjm).toFixed(2) + ' €').replace('.', ',')}
                  </td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
                <tr>
                  <td colSpan='4'></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='infoPrice'>
            <table className='tablePrice'>
              <tbody>
                <tr>
                  <td>MONTANT TOTAL HT</td>
                  <td className='priceNumber'>
                    {((workDay * tjm).toFixed(2) + ' €').replace('.', ',')}
                  </td>
                </tr>
                <tr>
                  <td>TVA</td>
                  <td className='priceNumber'>20 %</td>
                </tr>
                <tr>
                  <td>MONTANT TOTAL TTC</td>
                  <td className='priceNumber'>
                    {((workDay * tjm * 1.2).toFixed(2) + ' €').replace(
                      '.',
                      ','
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='spanInvoice'>
            <span className='spanPrice'>
              Chèques ou virement à l’ordre de OMDATA Consulting
            </span>
            <br />
            <br />
            <span className='thankfulMessage'>
              Nous vous remercions de votre confiance.
            </span>
          </div>
          <div className='infoIban'>
            <table>
              <tbody>
                <tr>
                  <td>IBAN</td>
                  <td>FR76 1695 8000 0147 1492 4313 884</td>
                </tr>
                <tr>
                  <td>BIC</td>
                  <td>QNTOFRP1XXX</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='generalCondition'>
            <p>
              CONDITIONS GÉNÉRALES
              <br />
              Escompte pour règlement anticipé : 0%
              <br />
              En cas de retard de paiement, une pénalité égale à 3 fois le taux
              d'intérêt légal sera exigible (Décret 2009-138 du 9 février 2009).
              Pour les professionnels, une indemnité minimum forfaitaire de 40
              euros pour frais de recouvrement sera exigible (Décret 2012-1115
              du 9 octobre 2012).
            </p>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default Facture;
