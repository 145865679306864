import { Alert,Box, Button, DialogTitle, Paper, Snackbar } from '@mui/material';
import { useState, useEffect,useContext } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import employeesContext from '../../context';

function EditCollab({ id, setOpenPopUp }) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { setEmployeesList } = useContext(employeesContext);

  const [errorMsg, setErrorMsg] = useState('');

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [showPwd, setShowPwd] = useState(false);
  const handleClickShowPassword = () => {
    setShowPwd(!showPwd);
  };

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');


  useEffect(() => {
    fetch(`/app/employees/${id}`)
      .then((res) => res.json())
      .then((response) => {
        setFname(response[0].firstName);
        setLname(response[0].lastName);
        setEmail(response[0].email);
        setAddress(response[0].address);
        setPhone(response[0].phone);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const updateEmployee = (id) => {
    fetch(`/app/employees/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: fname,
        lastName: lname,
        email: email,
        phone: phone,
        address: address,
        password: password,
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.err) throw new Error(data.err);
        fetch('/app/employees')
          .then((res) => res.json())
          .then((data) => {
            setEmployeesList(data);
            setOpenPopUp(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        setErrorMsg(err.message);
        setState({ ...state, open: true });
      });
  };

  return (
    <div className='collabs'>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity='error'
          sx={{
            width: '100%',
            color: '#EA4C3C',
            fontFamily: 'Poppins, sans-serif !important',
          }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <h1>Editer Collaborateur</h1>
      </DialogTitle>
      <div className={userInfo.isAdmin === 'noadmin' && 'noadmin_container'}>
        <div className='collabs__container'>
          <Paper
            elevation={1}
            sx={{
              padding: '3rem',
            }}
          >
            <div className='input_container'>
              <div className='input'>
                <label htmlFor='prenom'>Nom</label>
                <input
                  id='prenom'
                  type='text'
                  value={lname}
                  onChange={(event) => {
                    setLname(event.target.value);
                  }}
                />
              </div>
              <div className='input'>
                <label htmlFor='nom'>Prénom</label>
                <input
                  id='nom'
                  type='text'
                  value={fname}
                  onChange={(event) => {
                    setFname(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className='input_container'>
              <div className='input'>
                <label htmlFor='email'>Email</label>
                <input
                  id='email'
                  type='email'
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
              <div className='input'>
                <label htmlFor='mdp'>Mot de Passe</label>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    position: 'relative',
                    width: '100%',
                  }}
                  className='show-pwd'
                >
                  <input
                    id='mdp'
                    type={showPwd ? 'text' : 'password'}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    placeholder='************'
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: 0,
                      bottom: '50%',
                      transform: 'translate(0,50%)',
                    }}
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                  >
                    {showPwd ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
              </div>
            </div>
            <div className='input_container'>
              <div className='input'>
                <label htmlFor='tel'>Téléphone</label>
                <input
                  id='tel'
                  type='text'
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
              </div>
              <div className='input'>
                <label htmlFor='ad'>Adresse</label>
                <input
                  id='ad'
                  type='text'
                  value={address}
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                />
              </div>
            </div>
            <Button
              onClick={() => {
                updateEmployee(id);
              }}
              variant='contained'
              sx={{
                backgroundColor: '#DBA09E',
                boxShadow: 'none',
                fontFamily: 'Poppins, sans-serif !important',

                '&:hover': {
                  backgroundColor: '#EA4C3C',
                },
              }}
            >
              Mettre à jour
            </Button>
            <Button
              onClick={() => {
                setOpenPopUp(false)
              }}
              variant='outlined'
              sx={{
                marginLeft: '.5rem',
                borderColor: '#393A62',
                color: '#393A62',
                fontFamily: 'Poppins, sans-serif !important',

                '&:hover': {
                  backgroundColor: '#393A62',
                  color: 'white',
                  border: 'none',
                },
              }}
            >
              Annuler
            </Button>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default EditCollab;
