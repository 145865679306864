import { Alert, Button, DialogTitle, Paper, Snackbar } from '@mui/material';
import { useContext, useState } from 'react';
import Context from '../../context';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';

function AffecterCollab({ prId, setOpenAffect }) {
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [errorMsg, setErrorMsg] = useState('');
  const { setProjectList, employeesList } = useContext(Context);

  const [EmpId, setEmpId] = useState(employeesList[0]?.id || '');
  const [tjm, setTjm] = useState('');
  const [date, setDate] = useState(null);
  const [dateInput, setDateInput] = useState(null);

  const addWork = () => {
    fetch('/app/work', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        EmpID: EmpId,
        PrID: prId,
        tjm: tjm,
        date: date,
      }),
    })
      .then((res) => {
        res.json();
      })
      .then((data) => {
        fetch('/app/projets')
          .then((res) => res.json())
          .then((data) => {
            setProjectList(data);
            setOpenAffect(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!EmpId) {
      setErrorMsg('Sélectionnez un collaborateur');
      setState({ ...state, open: true });
    } else {
      //check the date format
      if (
        date != null &&
        date.match(/^[2][0][0-9]{2}[\-]([0][0-9]|[1][0-2])$/)
      ) {
        addWork();
      } else {
        setErrorMsg('Sélectionnez une date valide');
        setState({ ...state, open: true });
      }
    }
  };

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    if (date.getMonth() < 9) {
      setDate(year + '-0' + (date.getMonth() + 1));
    } else {
      setDate(year + '-' + (date.getMonth() + 1));
    }
    setDateInput(date);
  };

  return (
    <div className='collabs'>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity='error'
          sx={{
            width: '100%',
            color: '#EA4C3C',
            fontFamily: 'Poppins, sans-serif !important',
          }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <h1>Affecter un collaborateur au projet</h1>
      </DialogTitle>
      <div className='collabs__container'>
        <Paper
          elevation={1}
          sx={{
            padding: '3rem',
          }}
        >
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='email'>Collaborateur concerné</label>
              <select
                required
                onChange={(e) => {
                  setEmpId(e.target.value);
                }}
                value={EmpId}
              >
                {employeesList.map((employee) => (
                  <option value={employee.id} key={employee.id}>
                    {employee.firstName + ' ' + employee.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className='input'>
              <label htmlFor='nom'>TJM (€)</label>
              <input
                id='nom'
                type='text'
                value={tjm}
                onChange={(event) => {
                  setTjm(event.target.value);
                }}
                required
              />
            </div>
          </div>
          <div className='input_container'>
            <div className='input'>
              <label htmlFor='mdp'>Date</label>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <DatePicker
                  className='dataPicker'
                  views={['month', 'year']}
                  value={dateInput}
                  inputVariant='outlined'
                  dateformat='yyyy/MM'
                  minDate='2018'
                  placeholder='Sélectionnez une date...'
                  maxDate='2100'
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <Button
            onClick={handleSubmit}
            variant='contained'
            sx={{
              backgroundColor: '#DBA09E',
              fontFamily: 'Poppins, sans-serif !important',

              '&:hover': {
                backgroundColor: '#EA4C3C',
              },
            }}
          >
            Affecter
          </Button>
        </Paper>
      </div>
    </div>
  );
}

export default AffecterCollab;
