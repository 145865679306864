import './Admin.css';
import Avatar from '@mui/material/Avatar';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import oussama from './oussama.png';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import MiniDrawer from './Drawer';

function Admin() {
  // const navigate = useNavigate();
  // const handleLogOut = () => {
  //   localStorage.removeItem('userInfo');
  //   navigate('/');
  // };

  return (
    <div className='dashboard'>
      {/* <div className='menu'>
        <div className='profile'>
          <Avatar
            variant='rounded'
            src={oussama}
            sx={{ width: 65, height: 65 }}
          ></Avatar>
          <div className='person'>
            <p>Oussama Mamoun</p>
            <p>Directeur</p>
          </div>
        </div>
        <ul className='menu_items'>
          <NavLink to='/admin' className='menu_item' end>
            <HomeOutlinedIcon fontSize='large' /> Accueil
          </NavLink>
          <NavLink to='/admin/collaborateurs' className='menu_item'>
            <GroupsOutlinedIcon fontSize='large' />
            Collaborateurs
          </NavLink>
          <NavLink to='/admin/projets' className='menu_item'>
            <WorkOutlineOutlinedIcon fontSize='large' />
            Projets
          </NavLink>
          <NavLink to='/admin/calendrier' className='menu_item'>
            <CalendarMonthOutlinedIcon fontSize='large' />
            Calendrier
          </NavLink>
          <li className='menu_item' onClick={handleLogOut}>
            <LogoutIcon fontSize='large' />
            Déconnexion
          </li>
        </ul>
      </div> */}
      <MiniDrawer />
      <div className='feed'>
        <Outlet />
      </div>
    </div>
  );
}

export default Admin;
